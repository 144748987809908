import { Link } from "gatsby"
import Grid from '@material-ui/core/Grid'
import React from "react"
import styled from "styled-components"
import Shield from "../images/stalbans-shield.png"

const HeaderContainer = styled(Grid)`
  margin-bottom: 1 rem;
  padding: 0;
  overflow: hidden;
  height: 60px;
  background: black;
`
const LogoText = styled.div`
  width: calc(100px + (200 - 100) * ((100vw - 300px) / (1600 - 300)));
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: calc(12px + (24 - 12) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1em + (1.5 - 1) * ((100vw - 300px) / (1600 - 300)));
  margin-left: calc(10px + (20 - 10) * ((100vw - 300px) / (1600 - 300)));
  color: white;
  margin: 0.5vh auto auto 10px;
`
const LogoContainer = styled.div`
  margin-right: 2%;
  padding: 0 0 50px 30px;
  float: left;
  height: 45px;
  display: flex;
`
const HeaderMenu = styled.div`
  padding-top: calc(5px + (10 - 5) * ((100vw - 300px) / (1600 - 300)));
  float: left;
`
const MenuLink = styled.div`
  padding-left: calc(10px + (30 - 10) * ((100vw - 300px) / (1600 - 300)));
  color: #f2f2f2;
  float left;
  Font-Family: raleway;
  font-style: normal;
  font-weight: bold;
  font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
  line-height: calc(1.3em + (2 - 1) * ((100vw - 300px)/(1600 - 300)));
  position: relative;
`

const PageHeader = () => {
  return (
    <HeaderContainer container>
      <Grid item xs={5} md={3}>
        <Link to="/">
          <LogoContainer>
            <img
              style={{ float: "left", width: 34, height: 35, Margin: 20 }}
              src={Shield}
              alt="Episcopal Shield"
            />
            <LogoText>St.Alban's Episcopal Church</LogoText>
          </LogoContainer>
        </Link>
      </Grid>
      <Grid item xs={7} md={9}>
        <HeaderMenu>
            <Link to="/AboutUs">
              <MenuLink>About Us</MenuLink>
            </Link>
            <Link to="/Calendar">
              <MenuLink>Calendar</MenuLink>
            </Link>
            <Link to="/SpiritualGrowth">
              <MenuLink>Spiritual Growth</MenuLink>
            </Link>
            <Link to="/Videos">
              <MenuLink>Videos</MenuLink>
            </Link>
            <Link to="/Community">
              <MenuLink>Community</MenuLink>
            </Link>
            <Link to="/News">
              <MenuLink>News</MenuLink>
            </Link>
            <Link to="/MailingList">
              <MenuLink>Mailing List</MenuLink>
            </Link>
        </HeaderMenu>
      </Grid>
    </HeaderContainer>
  )
}

export default PageHeader
